.error-message {
  color: red;
  margin: 3px 3px;
  font-size: 0.9rem;
}

.table-notification {
  margin-bottom: 1rem;
  margin-top: -1.2rem;
}

.CircularProgressbar {
  width: 5rem !important;
}

.previous-file {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: -0.5rem;
}

.supportingMediaBox {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.supportingMediaBoxMediaLibrary {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.supportingMediaLabel {
  font-weight: 700;
}

.selectedBeacon {
  margin-top: -5px;
  margin-left: 10px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
}

.selectedAssistanceBeacon {
  margin-left: 1px;
  margin-bottom: 1rem;
  display: inline-block;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 10px;
}

.media-library-image:hover {
  opacity: 0.6;
}